import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TempIdService {

  constructor() { }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * This method will return a HTML id safe value for unique identefier
   * @param id 
   * @param name 
   */
  getIdSafeValue(identifierOne: string, identifierTo?: string){
    if(identifierOne && identifierTo){
      return "s_" + identifierOne.replace(/ /g, '').replace('(', '').replace(')', '').replace(' ', '').replace('.', '').replace(',', '').replace(';', '').replace(':', '').replace('/', '').replace('-', '') + "_" + identifierTo.replace(/ /g, '').replace('(', '').replace(')', '').replace(' ', '').replace('.', '').replace(',', '').replace(';', '').replace(':', '').replace('/', '').replace('-', '');
    }

    if(identifierOne){
      return "s_" + identifierOne.replace(/ /g, '').replace('(', '').replace(')', '').replace(' ', '').replace('.', '').replace(',', '').replace(';', '').replace(':', '').replace('/', '').replace('-', '');
    }

    return null;
  }
}
