import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { CalculationService } from 'src/app/services/Calculation/calculation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Tender } from 'src/app/shared/objects/tender';
import { EstablishmentSettings } from 'src/app/shared/objects/establishment-settings';

@Component({
  selector: 'app-vat-summary',
  standalone: true,
  imports: [],
  templateUrl: './vat-summary.component.html',
  styleUrl: './vat-summary.component.scss'
})
export class VatSummaryComponent implements OnInit, OnDestroy{

  @Input() arrangements?: Arrangement[];
  @Input() tender?: Tender;
  @Input() tenderTotal?: BehaviorSubject<number>;
  @Input() isExportComponent?: boolean;
  @Input() establishmentSettings?: EstablishmentSettings;
  @Input() usedInInvoice?: boolean;

  total: string = "#";
  arrangementTotalLow?: string;
  lowVatTotalResult: string = "#";
  highVatTotalResult: string = "#";
  lowArrangementsExVatResult: string = "#";
  highArrangementsExVatResult: string = "#";
  vatTotal: string = "#";
  exVatTotal: string = "#";
  totalLow: string = "#";
  totalhigh: string = "#";
  alert: boolean = true;
  subscribtions: (Subscription | undefined)[] = []; 

  constructor(private calculationService: CalculationService){
  }

  ngOnInit() : void {
    this.subscribtions.push(this.tenderTotal?.subscribe(tenderTotal => {
      this.determineVatTotals();
    }));
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * This method will determine the Price structure and VAT prices
   * @returns Tender total
   */
  determineVatTotals() : string{
    if(!this.arrangements || !this.tender){
      this.alert = true;
      return "#";
    }

    this.alert = false;
    var lowVatTotal = 0;
    var highVatTotal = 0;
    var lowArrangementsExVat = 0;
    var highArrangementsExVat = 0;

    this.arrangements.forEach(a => {
      var totalPrice = this.calculationService.getTotalPriceForArrangement(a, this.tender!, this.usedInInvoice);
      if(totalPrice == "#"){
        this.alert = true;
        return;
      }

      switch(a.vatPercentage){
        case "9":
          // Price is Incl. Vat --> Devide by 1.09 to get it whitout VAT
          var vat = (Number(totalPrice) / 1.09) * 0.09
          lowVatTotal += vat;
          lowArrangementsExVat += Number(totalPrice) - vat;
          break;
        case "21":
          // Price is Incl. Vat --> Devide by 1.21 to get it whitout VAT
          var vat = (Number(totalPrice) / 1.21) * 0.21
          highVatTotal += vat;
          highArrangementsExVat += Number(totalPrice) - vat;
          break;
        case "9/21":
          var split = a.vatSplitPercentage?.split('/');

          if(split?.length != 2){
            this.alert = true;
            return;
          }

          // Determine the percentages to split in
          var percentageLow = Number(split[0]);
          var percentageHigh = Number(split[1]);

          if(percentageLow + percentageHigh != 100){
            this.alert = true;
            return;
          }

          var vatLow = (((Number(totalPrice) / 100) * percentageLow) / 1.09) * 0.09;
          var vatHigh = (((Number(totalPrice) / 100) * percentageHigh) / 1.21) * 0.21;

          lowVatTotal += vatLow;
          highVatTotal += vatHigh;

          lowArrangementsExVat += ((Number(totalPrice) / 100) * percentageLow) - vatLow;
          highArrangementsExVat += ((Number(totalPrice) / 100) * percentageHigh) - vatHigh;

          break;

        default:
          this.alert = true;
          break;
      }

    });

    this.lowVatTotalResult = lowVatTotal.toFixed(2);
    this.highVatTotalResult = highVatTotal.toFixed(2);
    this.lowArrangementsExVatResult = lowArrangementsExVat.toFixed(2);
    this.highArrangementsExVatResult = highArrangementsExVat.toFixed(2);
    this.vatTotal = (lowVatTotal + highVatTotal).toFixed(2);
    this.exVatTotal = (lowArrangementsExVat + highArrangementsExVat).toFixed(2);
    this.totalLow = (lowVatTotal + lowArrangementsExVat).toFixed(2);
    this.totalhigh = (highVatTotal + highArrangementsExVat).toFixed(2);
    this.total = (lowVatTotal + lowArrangementsExVat + highVatTotal + highArrangementsExVat).toFixed(2);

    return this.total;
  }

}
