@if(tender){
    <div class="container arrangements">
        <div class="row arrangements-section">
            <div class="col arrangements-wrapper">
                <div class="header-actions row align-items-center mb-2">
                    <h5 class="col">Gekozen arrangementen</h5>
                    <div class="col d-flex justify-content-end align-items-center arrangement-attached-actions">
                        <div class="switch-wrapper">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="minimizedViewModusActive" [(ngModel)]="minimizeArrangements">
                                <label class="form-check-label" for="minimizedViewModusActive">Compacte weergave</label>
                            </div>
                        </div>
                        @if(usedInInvoicePreparation){
                            @let syncOptionAvailable = syncAvailable();
                            <button class="btn btn-secondary" (click)="syncArrangements()" [disabled]="!syncOptionAvailable">
                                @if(syncOptionAvailable){
                                    <i class="bi bi-arrow-repeat"></i> Synchroniseren
                                } @else {
                                    <i class="bi bi-check-circle"></i> Gesynchroniseerd
                                }
                            </button>
                        }
                    </div>
                </div>
                <ul class="list-group" cdkDropList id="arrangementslist" (cdkDropListDropped)="drop($event)">
                    @for(arrangement of getArrangements(); track arrangement){
                        <li class="list-group-item" id="arrangement-{{arrangement.$id}}" cdkDrag>
                            <div class="drag-placeholder" *cdkDragPlaceholder></div>
                            <div class="arrangement-wrapper" [ngStyle]="{'background-color': getSignalColor(arrangement)}">
                                <div class="arrangement container">
                                    <div class="row">
                                        @if(arrangement.isCustomArrangement){
                                            <div class="col arrangement-name">
                                                <div class="custom-arrangement-name col">
                                                    <i class="bi bi-arrow-down-up drag-handle" cdkDragHandle></i><input type="text" class="form-control" placeholder="Arrangementnaam" [(ngModel)]="arrangement.name" />
                                                </div>
                                            </div>
                                        } @else {
                                            <div class="col arrangement-name">
                                                <span><i class="bi bi-arrow-down-up drag-handle" cdkDragHandle></i>{{arrangement.name}} - €{{arrangement.price}}</span>
                                                <span> </span>
                                            </div>
                                        }

                                        @if(arrangement.priceType == 'p.s.'){
                                            <div class="col-2 icon-col">
                                                <i class="bi bi-plus-lg"></i>
                                                @if(usedInInvoicePreparation){
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal stuks" [(ngModel)]="arrangement.invoiceAmountOfPax">
                                                } @else{
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal stuks" [(ngModel)]="arrangement.amountOfPax">
                                                }
                                            </div>
                                        } @else {
                                            <div class="col-2 icon-col">
                                                <i class="bi bi-person"></i>
                                                @if(usedInInvoicePreparation){
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Pax" [(ngModel)]="arrangement.invoiceAmountOfPax">
                                                } @else {
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Pax" [(ngModel)]="arrangement.amountOfPax">
                                                }
                                            </div>
                                        }
                                        @if(arrangement.priceType != 'p.s.' && arrangement.priceType != 'p.p.'){
                                            <div class="col-2 icon-col">
                                                <i class="bi bi-clock"></i>
                                                @if(usedInInvoicePreparation){
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Uur" [(ngModel)]="arrangement.invoiceAmountOfHour">
                                                } @else {
                                                    <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Uur" [(ngModel)]="arrangement.amountOfHour">
                                                }
                                            </div>
                                        }
                                        <div class="col-2 icon-col">
                                            <i class="bi bi-currency-euro"></i>
                                            @if(usedInInvoicePreparation){
                                                <input class="form-control" type="number" min="0" step="0.1" placeholder="Prijs" [disabled]="usedInTemplate ?? false" [(ngModel)]="arrangement.invoicePrice">
                                            } @else {
                                                <input class="form-control" type="number" min="0" step="0.1" placeholder="Prijs" [disabled]="usedInTemplate ?? false" [(ngModel)]="arrangement.price">
                                            }
                                        </div>
                                        <div class="col-auto">
                                            <button type="button remove-arrangement-button" class="btn btn-danger" (click)="removeArrangementToTender(arrangement)">-</button>
                                        </div>
                                    </div>
                                    @if(arrangement.description && !minimizeArrangements){
                                        <div>
                                            <hr>
                                        </div>
                                    }
                                    @if(!minimizeArrangements){
                                        <div class="row">
                                            @if(!arrangement.isCustomArrangement && arrangement.description){
                                                <div class="col arrangement-description">
                                                    <label>Beschrijving:</label>
                                                    <div class="add-internal-description">
                                                        <div class="description-lines">
                                                            @for(descriptionLine of arrangement.description.split('\n'); track $index){
                                                                <span>{{descriptionLine}}</span>
                                                            }
                                                        </div>
                                                        @if(!arrangement.internalDescription && establishment?.useInternalDescriptionWhenAvailable && !usedInTemplate  && !usedInInvoicePreparation){
                                                            <button type="button" class="btn btn-secondary action-button add-arrangement" (click)="addInternalDescription(arrangement)"><i class="bi bi-file-text"></i></button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            @if(arrangement.isCustomArrangement){
                                                <div class="col arrangement-description">
                                                    <label>Beschrijving:</label>
                                                    <textarea [(ngModel)]="arrangement.description" class="form-control" rows="2"></textarea>
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if(arrangement.internalDescription && establishment?.useInternalDescriptionWhenAvailable && !minimizeArrangements){
                                        <div>
                                            <hr>
                                        </div>
                                    }
                                    @if(arrangement.internalDescription && !minimizeArrangements && establishment?.useInternalDescriptionWhenAvailable && !usedInInvoicePreparation){
                                        <div class="row">
                                            <div class="col">
                                                <label>Interne Beschrijving:</label>
                                                <div class="internal-description-input-area">
                                                    <textarea class="form-control" rows="1" [(ngModel)]="arrangement.internalDescription"></textarea>
                                                    @if(arrangement.internalDescription){
                                                        <button type="button" class="btn btn-danger" (click)="removeInternalDescription(arrangement)"><i class="bi bi-dash-circle"></i></button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    @if(!usedInTemplate && !minimizeArrangements && !usedInInvoicePreparation){
                                        <div>
                                            <hr>
                                        </div>
                                        <div class="row">
                                            <div class="col arrangement-notice">
                                                <label>Opmerking:</label>
                                                <textarea class="form-control" maxlength="1500" rows="1" [(ngModel)]="arrangement.notice"></textarea>
                                                <div class="char-counter">
                                                    @if(arrangement.notice){
                                                        <div class="length-warning">
                                                            @if(arrangement.notice!.length >= 1500){
                                                                <i class="bi bi-exclamation-triangle-fill"></i>
                                                                <small>Het maximaal aantal tekens is 1500</small>
                                                            }
                                                        </div>
                                                        <small [ngClass]="arrangement.notice.length >= 1500 ? 'exceeded' : 'counter'">{{arrangement.notice.length}}/1500</small>
                                                    } @else {
                                                        <small>0/1500</small>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    @if(activePackage?.kitchenSheet && !minimizeArrangements){
                                        @if(arrangement.openPriceSection){
                                            <div class="row">
                                                <div class="col-2 form-group">
                                                    <label for="vatPercentageInput">BTW (%)</label>
                                                    <select class="form-select" id="vatPercentageInput" [(ngModel)]="arrangement.vatPercentage" (ngModelChange)="vatPercentageChanged(arrangement)">
                                                        <option [value]="'9'">9%</option>
                                                        <option [value]="'21'">21%</option>
                                                        <option [value]="'9/21'">9%/21%</option>
                                                    </select>
                                                </div>
                                                @if(arrangement.vatPercentage?.includes('/')){
                                                    <div class="col-3 form-group">
                                                        <label for="vatSplitInput">Verdeling Laag/Hoog (%)</label>
                                                        <select class="form-select" id="vatSplitInput" [(ngModel)]="arrangement.vatSplitPercentage" [disabled]="arrangement.vatPercentage != '9/21'">
                                                            <option [value]="'95/5'">95/5</option>
                                                            <option [value]="'90/10'">90/10</option>
                                                            <option [value]="'80/20'">80/20</option>
                                                            <option [value]="'70/30'">70/30</option>
                                                            <option [value]="'60/40'">60/40</option>
                                                            <option [value]="'50/50'">50/50</option>
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div class="row">
                                            <div class="col extra-settings">
                                                @if(arrangement.isCustomArrangement){
                                                    <select class="form-select custom-price-type" [(ngModel)]="arrangement.priceType">
                                                        @for(opt of priceTypes; track opt.description){
                                                            <option [ngValue]="opt.value">{{opt.description}}</option>
                                                        }
                                                    </select>
                                                }
                                                @if(!usedInTemplate){
                                                    <button type="button" class="arrangrement-prices"  [ngClass]="!arrangement.openPriceSection ? 'btn btn-secondary' : 'btn btn-success'" (click)="togglePriceSection(arrangement)">
                                                        <i class="bi bi-currency-euro"></i>
                                                        @if(arrangement.openPriceSection){
                                                            <i class="bi bi-arrow-up-short"></i>
                                                        } @else {
                                                            <i class="bi bi-arrow-down-short"></i>
                                                        }
                                                    </button>

                                                    @if (!usedInInvoicePreparation) {
                                                        <button class="btn btn-secondary arrangement-settings" (click)="toggleArrangementSettings(arrangement)"><i class="bi bi-gear"></i></button>
                                                    }
                                                }

                                                @if(arrangement.openPreferences){
                                                    <div class="preferences-input">
                                                        <div class="preferences-header">
                                                            <span>Voorkeuren:</span>
                                                            <div class="close-prefs" (click)="toggleArrangementSettings(arrangement)"><i class="bi bi-x-lg"></i></div>
                                                        </div>
                                                        <div class="preferences-area">
                                                            <div class="switch-wrapper">
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" role="switch" id="hideOnKitchenSheet" [(ngModel)]="arrangement.hideOnKitchenSheet"/>
                                                                    <label class="form-check-label" for="hideOnKitchenSheet">Verbergen op Keuken-Sheet</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </li>
                    } @empty {
                        <span class="alert alert-primary no-arrangements"><i class="bi bi-caret-right"></i>Er zijn nog geen arrangementen toegevoegd!</span>
                    }
                </ul>
            </div>
        </div>
        @if(!usedInTemplate){
            <div class="row add-arrangement">
                <div class="col actions">
                    <button type="button" class="btn btn-secondary" id="add-custom-arrangement" data-bs-toggle="tooltip" data-bs-placement="top" title="Custom Arrangement Toevoegen" (click)="addCustomArrangement()"> + </button>
                </div>
            </div>
        }
        <app-arrangement-categories [tender]="tender" [usedInTemplate]="usedInTemplate" [usedInInvoicePreparation]="usedInInvoicePreparation"></app-arrangement-categories>
    </div>
}
