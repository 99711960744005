import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TempIdService } from 'src/app/services/Guid/temp-id.service';
import { ActivePackage } from 'src/app/shared/objects/activePackage';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { Establishment } from 'src/app/shared/objects/establishment';
import { Tender } from 'src/app/shared/objects/tender';
import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ArrangementCategoriesComponent } from "../arrangement-categories/arrangement-categories.component";
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ArrangementCategoriesComponent
],
  selector: 'app-arrangement-values',
  templateUrl: './arrangement-values.component.html',
  styleUrls: ['./arrangement-values.component.scss'],
})
export class ArrangementValuesComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() establishment?: Establishment;
  @Input() usedInTemplate?: boolean;
  @Input() usedInInvoicePreparation?: boolean;

  activePackage?: ActivePackage;
  subscribtions: (Subscription | undefined)[] = []; 
  minimizeArrangements: boolean = false;
  priceTypes = [
    {
      value: "p.p.p.u.",
      description: "Per persoon per uur"
    },
    {
      value: "p.p.",
      description: "Per persoon"
    },
    {
      value: "p.s.",
      description: "Per stuk"
    },
  ];

  constructor(private guidService: TempIdService, private communicationService: InternalCommunicationService) { }

  ngOnInit(): void {
    this.subscribtions.push(this.communicationService.activePackage?.subscribe(activePackage => {
      this.activePackage = activePackage;
    }));

    // Order Arrangements based on index value
    this.tender?.arrangements?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));

    // If used while preparing an invoice then minimize the space needed for attached Arrangements
    if(this.usedInInvoicePreparation){
      this.minimizeArrangements = true;
    }
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * This method will determine which arrangement should be shown in the arrangement list
   * @returns 
   */
  getArrangements(){
    if(this.usedInInvoicePreparation){
      // Filter all Tender-Only arrangements out of the arrangement set if this component is used in the Invoice Prepration flow
      return (this.tender?.arrangements ?? []).filter(a => a.visibility == 'Both' || a.visibility == 'Invoice-Only' || !a.visibility)
    } else {
      // Filter all Invoice-Olny arrangements out of the arrangement set
      return (this.tender?.arrangements ?? []).filter(a => a.visibility == 'Both' || a.visibility == 'Tender-Only' || !a.visibility)
    }
  }

  /**
   * Handle Arrangement DropEvent
   * @param event 
   */
  drop(event: CdkDragDrop<Arrangement[]>) {
    moveItemInArray(this.tender?.arrangements ?? [], event.previousIndex, event.currentIndex);

    // Re-arrange index attributes
    var indexInTender = 0;
    this.tender?.arrangements?.forEach(a => {
      a.index = indexInTender;
      indexInTender++;
    });
  }

  /**
   * This method will open the arrangement settings
   * @param arrangement 
   */
  toggleArrangementSettings(arrangement: Arrangement){
    this.tender?.arrangements?.forEach(arr => {
      if(arrangement != arr){
        arr.openPreferences = false;
      }
    })

    arrangement.openPreferences = !arrangement.openPreferences;
  }

  /**
   * This method will remove a arrangement from the tender
   * @param arrangement 
   */
   removeArrangementToTender(arrangementToRemove: Arrangement){
    if(!this.tender || !this.tender?.arrangements || !arrangementToRemove){
      return;
    }

    if(this.usedInInvoicePreparation){
      // Hide the arrangement instead of removing
      arrangementToRemove.visibility = 'Tender-Only';
    } else {
      // Remove the actual arrangement from the Tender
      this.tender.arrangements = this.tender.arrangements?.filter(a => a.uniqueIdentifier != arrangementToRemove.uniqueIdentifier);
    }
  }

  /**
   * This method will add an internalDescription to the arrangmenent
   * @param arrangement 
   */
  addInternalDescription(arrangement: Arrangement){
    arrangement.internalDescription = " ";
  }

  /**
   * This method will remove an internalDescription to the arrangmenent
   * @param arrangement 
   */
  removeInternalDescription(arrangement: Arrangement){
  arrangement.internalDescription = undefined;
  }

  /**
   * This method will add a custom arrangement to the list of arrangements
   */
  addCustomArrangement(){
    if(!this.tender){
      return;
    }

    // Safety check
    if(!this.tender.arrangements){
      this.tender.arrangements = [];
    }

    this.tender.arrangements.push({
      $id: "",
      idInTender: this.guidService.newGuid(),
      isCustomArrangement: true,
      name: "",
      price: 0,
      priceType: "p.p.p.u.",
      vatPercentage: "9",
      uniqueIdentifier: this.guidService.newGuid(),
      index: (this.tender?.arrangements?.length ?? -1) + 1,
      // Open price section by default to mention that the Vat percentage needs to be filled in.
      openPriceSection: true,
      
      // Determine visibility based on the usasge of the component
      visibility: this.usedInInvoicePreparation ? 'Invoice-Only' : 'Both',

      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
    });
  }

  /**
   * This method will adjust the VatSplit variable if needed
   * @param arrangement 
   */
  vatPercentageChanged(arrangement: Arrangement){
    if(!arrangement.vatPercentage?.includes('/')){
      arrangement.vatSplitPercentage = undefined;
    }
  }

  /**
   * This method will open or close the price section of an arrangement
   * @param arrangement 
   */
  togglePriceSection(arrangement: Arrangement){
    arrangement.openPriceSection = !arrangement.openPriceSection
  }

  /**
   * This method will sync the differneces between the Tender arrangements and the invoice arrangements
   */
  syncArrangements(){
    if(!this.tender?.arrangements){
      return;
    }

    // Only update the arrangements which are visible in the invoice and present in the Tender
    this.tender.arrangements.filter(a => !a.visibility || a.visibility == 'Both').forEach(a => {
      a.invoicePrice = a.price;
      a.invoiceAmountOfHour = a.amountOfHour;
      a.invoiceAmountOfPax = a.amountOfPax;
    });
  }

  /**
   * This method will determine the correct Signal color for this arrangement
   * @param arrangement 
   */
  getSignalColor(arrangement: Arrangement){
    if(!arrangement){
      return "#f2f2f2";
    }

    // Basic validation rules
    if(arrangement.price < 0 || (arrangement.amountOfPax ?? -1) < 0){
      return "#f8d7da";
    }

    // Validations rules for when preparing an Invoice
    if(this.usedInInvoicePreparation){
      
      // If there are differences between Invoice and Tender then adjust basic color
      if(arrangement.invoiceAmountOfHour != arrangement.amountOfHour ||
        arrangement.invoiceAmountOfPax != arrangement.amountOfPax ||
        arrangement.invoicePrice != arrangement.price
      ){
        return "#fff3cd";
      }
    }

    return "#f2f2f2";
  }

  /**
   * This method will determine if a sync between the Invoice and Tender is needed or possible
   * @returns 
   */
  syncAvailable(){
    if(!this.tender?.arrangements){
      return false;
    }

    let syncNeeded = false;
    this.tender.arrangements.forEach(arrangement => {
      if(syncNeeded){
        return;
      }

      if(arrangement.invoiceAmountOfHour != arrangement.amountOfHour ||
        arrangement.invoiceAmountOfPax != arrangement.amountOfPax ||
        arrangement.invoicePrice != arrangement.price
      ){
        syncNeeded = true;
      }
    });

    return syncNeeded;
  }
}
