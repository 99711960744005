import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PdfService {

  inExportMode: boolean = false;

  constructor() { }

  /**
   * This method set the Export Mode active
   */
  exportTender() {
    this.inExportMode = true;
  }
}
