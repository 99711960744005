import { Injectable } from '@angular/core';
import { ID, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { EventType } from 'src/app/shared/objects/eventType';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventTypesService {

  constructor() { }

  createEventType(eventType: EventType, teamId: string) {
    return Api.database.createDocument(environment.database, "EventType", ID.unique(), this.createPartialEventType(eventType), [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))])
  }

  updateEventType(eventType: EventType) {
    return Api.database.updateDocument(environment.database, "EventType", eventType.$id, this.createPartialEventType(eventType));
  }

  deleteEventType(eventType: EventType) {
    return Api.database.deleteDocument(environment.database, "EventType", eventType.$id);
  }

 getEstablishmentEventTypes(establishmentId: string) {
  return Api.database.listDocuments<EventType>(environment.database, "EventType", [Query.equal('establishmentId', establishmentId), Query.limit(100)]);
}


  createPartialEventType(eventType: EventType) : Partial<EventType>{
    return {
      name: eventType.name,
      establishmentId: eventType.establishmentId
    }
  }
}
