import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ArrangementService } from 'src/app/services/Arrangement/arrangement.service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TempIdService } from 'src/app/services/Guid/temp-id.service';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { ArrangementCategory } from 'src/app/shared/objects/components/arrangement-category';
import { Establishment } from 'src/app/shared/objects/establishment';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  selector: 'app-arrangement-categories',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './arrangement-categories.component.html',
  styleUrl: './arrangement-categories.component.scss'
})
export class ArrangementCategoriesComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() usedInTemplate?: boolean;
  @Input() usedInInvoicePreparation?: boolean;

  subscribtions: (Subscription | undefined)[] = []; 
  addedArrangement?: string;
  establishment?: Establishment;
  arrangementCategories?: ArrangementCategory[];

  constructor(private guidService: TempIdService, private communicationService: InternalCommunicationService, private arrangementService: ArrangementService){
  }

  ngOnInit(): void {
    this.subscribtions.push(this.communicationService.activeEstablishment?.subscribe(activeEstablishment => {
      this.establishment = activeEstablishment;
      this.getEstablishmentArrangements();
    }));
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * This method will get the arrangements which belong to the current Establishment
   * @returns 
   */
  getEstablishmentArrangements(){
    if(!this.establishment){
      return;
    }
    
    var establishmentContainer = this.communicationService.establishmentContainer.getValue();

    if(establishmentContainer?.establishment?.$id == this.establishment.$id && (establishmentContainer?.arrangementCategories?.length ?? 0) > 0){
      this.arrangementCategories = establishmentContainer.arrangementCategories;
      this.arrangementCategories = JSON.parse(JSON.stringify(establishmentContainer.arrangementCategories))
    } else {
      this.arrangementService.getArrangementCategories(this.establishment.$id).then(arrangementCategories => {
        this.arrangementCategories = JSON.parse(JSON.stringify(arrangementCategories));
        
        if(establishmentContainer){
          establishmentContainer.arrangementCategories = JSON.parse(JSON.stringify(arrangementCategories));
        }
      });
    }
  }

  /**
   * This method will collapse all open optional arrangements
   */
  collapseAllArrangementWrappers(){
    var arrangementsWrapper = document.getElementById('optional-arrangements');

    if (arrangementsWrapper != null) {
        var accordions = arrangementsWrapper.getElementsByClassName('collapse');

        if (accordions != null) {
            for (var i = 0; i < accordions.length; i++) {
                accordions[i].classList.remove("show");
                accordions[i].classList.add("hide");
            }
        }
    }
  }

  /**
   * This method will return a HTML safe identifier
   * @param firstIdentifier 
   * @param secondIdentifier 
  */
  getSafeIdentifier(firstIdentifier: string, secondIdentifier?: string){
    return this.guidService.getIdSafeValue(firstIdentifier, secondIdentifier);
  }

  /**
   * This method will determine how many of the present Arrangement are already attached to the Tender
   * @param arrangement 
   * @returns 
   */
  getAmountOfArrangementInTender(arrangement: Arrangement){
    if(this.usedInInvoicePreparation){
      return this.tender?.arrangements?.filter(a => a.name == arrangement.name && a.priceType == a.priceType && (!a.visibility || a.visibility == 'Both' || a.visibility == 'Invoice-Only'))?.length ?? 0;
    } else {
      return this.tender?.arrangements?.filter(a => a.name == arrangement.name && a.priceType == a.priceType)?.length ?? 0;
    }
  }

  /**
   * This method will add a arrangement to the tender
   * @param arrangement 
   */
  addArrangementToTender(arrangementToAdd: Arrangement){
    if(!this.tender || !arrangementToAdd){
      return;
    }

    // Safety check
    if(!this.tender.arrangements){
      this.tender.arrangements = [];
    }

    // Add arrangement ID
    this.tender.arrangements.push({
      $id: arrangementToAdd.$id,
      idInTender: this.guidService.newGuid(),
      name: arrangementToAdd.name,
      price: arrangementToAdd.price,
      priceType: arrangementToAdd.priceType,
      amountOfHour: arrangementToAdd.amountOfHour,
      amountOfPax: arrangementToAdd.amountOfPax,
      categoryId: arrangementToAdd.categoryId,
      description: arrangementToAdd.description,
      notice: arrangementToAdd.notice,
      internalDescription: arrangementToAdd.internalDescription,
      uniqueIdentifier: this.guidService.newGuid(),
      hideOnKitchenSheet: arrangementToAdd.hideOnKitchenSheet,
      index: (this.tender?.arrangements?.length ?? -1) + 1,
      vatPercentage: arrangementToAdd.vatPercentage,
      vatSplitPercentage: arrangementToAdd.vatSplitPercentage,

      // Determine visibility based on the usasge of the component
      visibility: this.usedInInvoicePreparation ? 'Invoice-Only' : 'Both',

      // Coppy prices if the arrangement is added as invoice arrangement
      invoiceAmountOfHour: this.usedInInvoicePreparation ? arrangementToAdd.amountOfHour : undefined,
      invoiceAmountOfPax: this.usedInInvoicePreparation ? arrangementToAdd.amountOfPax : undefined,
      invoicePrice: this.usedInInvoicePreparation ? arrangementToAdd.price : undefined,

      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: ""
    });

    // Set Arrangement id to trigger green flashing success event
    this.addedArrangement = arrangementToAdd.$id;

    // Reset value
    setTimeout(() => {
      this.addedArrangement = undefined;
    }, 1000);
  }
}
