@let totalPrice = determineVatTotals();
@if(alert && !isExportComponent){
    <div class="row mt-3">
        <div class="col">
            <div class="alert alert-danger alert-message" role="alert">
                <i class="bi bi-exclamation-triangle"></i> <strong> Let op:</strong> Eén of meerdere arrangementen bevatten ongeldige prijzen of BTW-gegevens. Deze opbouw zal <strong>niet</strong> getoond worden in een offerte.
              </div>
        </div>
    </div>
}
@if((isExportComponent && !alert && establishmentSettings?.showPriceStructureInTender) || !isExportComponent){
    <div class="row mt-1 price-structure">
        <div class="col-2 d-flex align-items-start flex-column">
            <span><strong>Prijsopbouw</strong></span>
            <span>9%</span>
            <span>21%</span>
            <span><strong>Totaal:</strong></span>
        </div>
        <div class="col-2 d-flex align-items-start flex-column">
            <span><strong>Netto</strong></span>
            <span>€ {{lowArrangementsExVatResult}}</span>
            <span>€ {{highArrangementsExVatResult}}</span>
            <span><strong>€ {{exVatTotal}}</strong></span>
        </div>
        <div class="col-2 d-flex align-items-start flex-column">
            <span><strong>BTW</strong></span>
            <span>€ {{lowVatTotalResult}}</span>
            <span>€ {{highVatTotalResult}}</span>
            <span><strong>€ {{vatTotal}}</strong></span>
        </div>
        <div class="col d-flex align-items-start flex-column">
            <span><strong>Totaal</strong></span>
            <span>€ {{totalLow}}</span>
            <span>€ {{totalhigh}}</span>
            <span><strong>€ {{totalPrice}}</strong></span>
        </div>
    </div>
}
