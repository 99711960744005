@if(arrangementCategories){
    <div class="row optional-arrangements-section">
        <div class="col" id="optional-arrangements">
            <div class="header-actions">
                <h5>Beschikbare arrangementen</h5>
                <button class="btn btn-link" (click)="collapseAllArrangementWrappers()">Arrangementen inklappen</button>
            </div>
            @if(arrangementCategories){
                <div class="arrangement-wrapper" id="accordionArrangements">
                    @for(arrangementCategory of arrangementCategories; track arrangementCategory.$id){
                        <div class="accordion" id="{{getSafeIdentifier(arrangementCategory.$id)}}">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="{{getSafeIdentifier(arrangementCategory.$id, arrangementCategory.name)}}">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + getSafeIdentifier(arrangementCategory.name, arrangementCategory.$id)" aria-expanded="true" [attr.aria-controls]="getSafeIdentifier(arrangementCategory.name, arrangementCategory.$id)">
                                        <i class="bi bi-list"></i>{{arrangementCategory.name}}
                                  </button>
                                </h2>
                                <div id="{{getSafeIdentifier(arrangementCategory.name, arrangementCategory.$id)}}" class="accordion-collapse collapse" [attr.aria-labelledby]="getSafeIdentifier(arrangementCategory.$id, arrangementCategory.name)" [attr.data-bs-parent]="getSafeIdentifier(arrangementCategory.$id)">
                                    <div class="accordion-body">
                                        @for(arrangement of arrangementCategory.arrangements; track arrangement.$id){
                                            <div class="arrangement optional-arrangement container" [ngClass]="addedArrangement == arrangement.$id ? 'successfull-added' : ''">
                                                <div class="row arrangement-content">
                                                    <div class="col arrangement-name">
                                                        <div>
                                                            <span>{{arrangement.name}} - €{{arrangement.price}}</span>
                                                            <span> </span>
                                                        </div>
                                                        <span class="attached-to-tender">Reeds gekoppeld: <strong>{{getAmountOfArrangementInTender(arrangement)}}</strong></span>
                                                    </div>
                                                    @if(arrangement.priceType == 'p.s.'){
                                                        <div class="col-2 icon-col">
                                                            <i class="bi bi-plus-lg"></i>
                                                            <input class="form-control" type="number" min="0" step="1" placeholder="Aantal stuks" [(ngModel)]="arrangement.amountOfPax">
                                                        </div>
                                                    } @else {
                                                        <div class="col-2 icon-col">
                                                            <i class="bi bi-person"></i>
                                                            <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Pax" [(ngModel)]="arrangement.amountOfPax">
                                                        </div>
                                                    }
                                                    @if(arrangement.priceType != 'p.s.' && arrangement.priceType != 'p.p.'){
                                                        <div class="col-2 icon-col">
                                                            <i class="bi bi-clock"></i>
                                                            <input class="form-control" type="number" min="0" step="1" placeholder="Aantal Uur" [(ngModel)]="arrangement.amountOfHour">
                                                        </div>
                                                    }
                                                    <div class="col-2 icon-col">
                                                        <i class="bi bi-currency-euro"></i>
                                                        <input class="form-control" type="number" min="0" step="0.1" placeholder="Prijs" [disabled]="usedInTemplate ?? false" [(ngModel)]="arrangement.price">
                                                    </div>
                                                    <div class="col-auto">
                                                        <button type="button add-arrangement-button" class="btn btn-success" (click)="addArrangementToTender(arrangement)">+</button>
                                                    </div>
                                                </div>
                                                @if(arrangement.description){
                                                    <div>
                                                        <hr>
                                                    </div>
                                                }
                                                <div class="row">
                                                    @if(arrangement.description){
                                                        <div class="col arrangement-description">
                                                            <label>Beschrijving:</label>
                                                            @for(descriptionLine of arrangement.description.split('\n'); track $index){
                                                                <div>
                                                                    <span>{{descriptionLine}}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                @if(!usedInTemplate){
                                                    <div>
                                                        <hr>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col arrangement-notice">
                                                            <label>Opmerking:</label>
                                                            <textarea  class="form-control" maxlength="1500" rows="1" [disabled]="usedInTemplate ?? false" [(ngModel)]="arrangement.notice"></textarea>
                                                            <div class="char-counter">
                                                                @if(arrangement.notice){
                                                                    <div class="length-warning">
                                                                        @if(arrangement.notice!.length >= 1500){
                                                                            <i class="bi bi-exclamation-triangle-fill"></i>
                                                                            <small>Het maximaal aantal tekens is 1500</small>
                                                                        }
                                                                    </div>
                                                                    <small [ngClass]="arrangement.notice.length >= 1500 ? 'exceeded' : 'counter'">{{arrangement.notice.length}}/1500</small>
                                                                } @else {
                                                                    <small>0/1500</small>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}