import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalculationService } from 'src/app/services/Calculation/calculation.service';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { EstablishmentSettings } from 'src/app/shared/objects/establishment-settings';
import { Tender } from 'src/app/shared/objects/tender';
import { VatSummaryComponent } from "../vat-summary/vat-summary.component";

@Component({
  standalone: true,
  selector: 'app-tender-summary',
  templateUrl: './tender-summary.component.html',
  styleUrls: ['./tender-summary.component.scss'],
  imports: [VatSummaryComponent]
})
export class TenderSummaryComponent implements OnInit {

  @Input() tender?: Tender
  @Input() establishmentSettings?: EstablishmentSettings
  @Input() isExportComponent?: boolean
  @Input() isSheet?: boolean; 
  @Input() isKitchenSheet?: boolean;
  @Input() tenderTotal?: BehaviorSubject<number>;
  @Input() usedInInvoice?: boolean;

  rowClass: string = "row";
  containerClass: string = "container";
  showPrices: boolean = true;
  useInternalDescriptionWhenAvailable: boolean = false;

  constructor(private calculationSerivce: CalculationService) { }

  ngOnInit(): void {
    if(this.isExportComponent){
      this.rowClass = "export-row";
      this.containerClass = "export-container";


      // Check if prices needs to be shown
      if(this.establishmentSettings){
        this.showPrices = this.establishmentSettings.displayPricesOnSheet;
        this.useInternalDescriptionWhenAvailable = this.establishmentSettings.useInternalDescriptionWhenAvailable;
      }
    }
  }

  /**
   * Return a subset of arrangements
   * @param arrangements 
   * @returns 
   */
  getFilteredArrangements(arrangements?: Arrangement[]){
    if(!arrangements){
      return [];
    }

    return arrangements.filter(a => !this.isKitchenSheet || (this.isKitchenSheet && !a.hideOnKitchenSheet));
  }

  /**
   * This method will calculate the total price of an arrangement
   * @param arrangement 
   * @returns 
   */
  getTotalPrice(arrangement: Arrangement){
    if(!this.tender || !arrangement){
      return "#";
    }

    return this.calculationSerivce.getTotalPriceForArrangement(arrangement, this.tender, this.usedInInvoice);
  }

  /**
   * This method will calculate the total price for the tender
   */
  getTotalTenderPrice(arrangements: Arrangement[] | undefined){
    if(!this.tender || !arrangements){
      return "#";
    }

    let totalPrice = 0;
    let invalidTotal = false;
    arrangements.forEach(arrangement => {
      let arrangementTotal = this.getTotalPrice(arrangement);

      // Return with Error
      if(arrangementTotal == "#"){
        invalidTotal = true;
        return; 
      }

      return totalPrice += Number(arrangementTotal);
    });

    if(invalidTotal){
      return "#";
    }

    // Only send update if value is different then previous value to prevent from to many updates
    // Used to calculate default initial deposit
    if(this.tenderTotal?.value != totalPrice){
      this.tenderTotal?.next(totalPrice);
    }
    return totalPrice.toFixed(2);
  }


  /**
   * This method will determine the price type of the arrangement
   * @param arrangement 
   */
  getPriceType(arrangement: Arrangement){
    if(!arrangement){
      return "#";
    }

    return this.calculationSerivce.getArrangementPriceType(arrangement);
  }

  /**
   * This method will return the correct price string
   * @param priceInput 
   */
     getPriceString(priceInput: Number){
      return Number(priceInput).toFixed(2).toString().replace('.', ',');
    }
}
