@if(tender && tender.arrangements && tender.arrangements.length > 0){
    <div class="{{containerClass}} summary-wrapper">
        <div class="{{rowClass}} header">
            <div class="col arrangement-header">
                Arrangement
            </div>
            <div class="summary-column center-column">
                Aantal
            </div>
            <div class="summary-column center-column">
                Aantal Uur
            </div>
            <div class="summary-column center-column">
                Prijs p.u/p.s
            </div>
            <div class="summary-column center-column">
                Totaalprijs
            </div>
        </div>
        @for(arrangement of getFilteredArrangements(tender.arrangements); track arrangement.$id){
            <div class="{{rowClass}} arrangement">
                <div class="col description-values">
                    <span class="name">{{arrangement.name}}</span>
                    @if(!useInternalDescriptionWhenAvailable || !arrangement.internalDescription || !isSheet){
                        <div class="description-lines">
                            @for(description of arrangement.description?.split('\n'); track $index){
                                <span>
                                    {{description}}
                                </span>
                            }
                        </div>
                    }
                    @if(useInternalDescriptionWhenAvailable && arrangement.internalDescription && isSheet){
                        <span>{{arrangement.internalDescription}}</span>
                    }
                    @if(arrangement.notice && arrangement.notice !=''){
                        <div class="notice">
                            <hr>
                            <div class="notice-wrapper">
                                <span class="notice-header">Opmerking:</span>
                                @for(descriptionLine of arrangement.notice.split('\n'); track descriptionLine){
                                    <div>
                                        <span>{{descriptionLine}}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div class="summary-column center-column">
                    @if(usedInInvoice){
                        {{arrangement.invoiceAmountOfPax}}
                    } @else {
                        {{arrangement.amountOfPax}}
                    }
                    @if((!arrangement.amountOfPax || arrangement.amountOfPax == 0) && arrangement.priceType == 'p.s.'){
                        <span class="quantity">Stuk</span>
                    }
                    @if(arrangement.priceType == 'p.s.' && arrangement.amountOfPax && arrangement.amountOfPax > 0){
                        <span class="quantity">Stuks</span>
                    } @else {
                        <span class="quantity">Pax</span>
                    }
                </div>
                <div class="summary-column center-column">
                    @if(usedInInvoice){
                        {{arrangement.invoiceAmountOfHour}}
                    } @else {
                        {{arrangement.amountOfHour}}
                    }
                </div>
                <div class="summary-column center-column">
                    @if(showPrices || !isSheet){
                        <span>
                            @if(usedInInvoice){
                                € {{arrangement.invoicePrice}} {{getPriceType(arrangement)}}
                            } @else {
                                € {{arrangement.price}} {{getPriceType(arrangement)}}
                            }
                        </span>
                    }
                </div>
                <div class="summary-column center-column">
                    @if(showPrices || !isSheet){
                        <span>
                            € {{getTotalPrice(arrangement)}}
                        </span>
                    }
                </div>
            </div>
        }
        @if(showPrices || !isSheet){
            <div class="{{rowClass}} totals">
                <div class="col totals-header">
                    Totaalprijs
                </div>
                <div class="summary-column center-column total-value">
                    <span>€ {{getTotalTenderPrice(tender.arrangements)}}</span>
                </div>
            </div>
        }
    </div>
    @if(showPrices || !isSheet){
        <app-vat-summary [tender]="tender" [establishmentSettings]="establishmentSettings" [isExportComponent]="isExportComponent" [tenderTotal]="tenderTotal" [arrangements]="tender.arrangements" [usedInInvoice]="usedInInvoice"></app-vat-summary>
    }
}
@if((!tender || !tender.arrangements || tender.arrangements.length == 0) && !isExportComponent){
    <div>
        <div class="{{rowClass}}">
            <div class="col">
                <div class="alert alert-warning" role="alert">
                    <strong>Whoops!</strong> Voeg eerst arrangementen toe alvorens er een samenvatting kan worden getoond.
                </div>
            </div>
        </div>
    </div>
}