import { Injectable } from '@angular/core';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { Tender } from 'src/app/shared/objects/tender';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }

  /**
   * This method will determine the total price of a tender based on the given arrangements
   * @param arrangements 
   */
  getTotalTenderPrice(arrangements: Arrangement[], tender: Tender){
    if(!arrangements){
      return -1;
    }

    var totalPrice = 0;
    arrangements.map(arrangement => {
      var result = this.getTotalPriceForArrangement(arrangement, tender);
      if(Number(result)){
        totalPrice += Number(result);
      }
    });

    return totalPrice;
  }

  /**
   * This method will calculate the total price of an arrangement
   * When usedInInvoice is set to true it will calculate with the Invoice Properties
   * @param arrangement 
   * @returns 
   */
   getTotalPriceForArrangement(arrangement: Arrangement, tender: Tender, usedInInvoice: boolean = false){
    if(!tender || !arrangement){
      return "#";
    }

    var priceType = this.getArrangementPriceType(arrangement);

    if(priceType == "p.s."){
      if(usedInInvoice){
        // Invoice Values
        if(arrangement.invoiceAmountOfPax == 0){
          // If 0 --> Then take 1 to show at least 1 piece
          return (0).toFixed(2);
        }
  
        if(arrangement.invoiceAmountOfPax){
          return ((arrangement.invoicePrice ?? 0) * arrangement.invoiceAmountOfPax).toFixed(2);
        }
        
      } else {
        if(arrangement.amountOfPax == 0){
          // If 0 --> Then take 1 to show at least 1 piece
          return (0).toFixed(2);
        }
  
        if(arrangement.amountOfPax){
          return (arrangement.price * arrangement.amountOfPax).toFixed(2);
        }
      }

      return "#";

    } else if(priceType =="p.p.p.u."){
      if(usedInInvoice){
        // Invoice Values
        // Safety check
        if((!arrangement.invoiceAmountOfHour || !arrangement.invoiceAmountOfPax) && arrangement.invoiceAmountOfPax != 0){
          return "#";
        }

        if(arrangement.invoiceAmountOfPax == 0){
          // If 0 --> Then take 1 to show at least price for one person
          return (0).toFixed(2);
        }

        return ((arrangement.invoicePrice ?? 0) * arrangement.invoiceAmountOfPax * (arrangement.invoiceAmountOfHour ?? 0)).toFixed(2);
      } else {
        // Safety check
        if((!arrangement.amountOfHour || !arrangement.amountOfPax) && arrangement.amountOfPax != 0){
          return "#";
        }

        if(arrangement.amountOfPax == 0){
          // If 0 --> Then take 1 to show at least price for one person
          return (0).toFixed(2);
        }

        return (arrangement.price * arrangement.amountOfPax * (arrangement.amountOfHour ?? 0)).toFixed(2);
      }

    } else if(priceType =="p.p."){
      if(usedInInvoice){
        // Invoice Values
        // Safety check
        if(!arrangement.invoiceAmountOfPax && arrangement.invoiceAmountOfPax != 0){
          return "#";
        }
      
        if(arrangement.invoiceAmountOfPax == 0){
          // If 0 --> Then take 1 to show at price for at least 1 person
          return (0).toFixed(2);
        }

        return ((arrangement.invoicePrice ?? 0) * arrangement.invoiceAmountOfPax).toFixed(2);
      } else {
        // Safety check
        if(!arrangement.amountOfPax && arrangement.amountOfPax != 0){
          return "#";
        }

        if(arrangement.amountOfPax == 0){
          // If 0 --> Then take 1 to show at price for at least 1 person
          return (0).toFixed(2);
        }

        return (arrangement.price * arrangement.amountOfPax).toFixed(2);
      }
    }
    return "#";
  }

  /**
   * This method will determine the price type of the arrangement
   * @param arrangement 
   */
   getArrangementPriceType(arrangement: Arrangement){
    if(!arrangement){
      return "#";
    }

    // For custom arrangements look at the hour input
    if(arrangement.priceType == "custom"){
      if(arrangement.amountOfHour && arrangement.amountOfHour > 0){
        return "p.p.p.u.";
      } else if(arrangement.amountOfPax && arrangement.amountOfPax > 0){
        return "p.p."
      } else {
        return "p.s."
      }
    } 

    return arrangement.priceType;
  }
}
