@if(tender && tender.partyValues && establishment){
    <div class="row">
        <div class="form-group col">
            <label for="kindofparty">Ter gelegenheid van</label>
            @if(isReadOnly){
                <input type="input" class="form-control-plaintext" readonly id="kindofparty" [(ngModel)]="tender.partyValues.partyName">
            } @else {
                <input maxlength="255" type="input" class="form-control" id="kindofparty" placeholder="Ter gelegenheid van" [(ngModel)]="tender.partyValues.partyName">
            }
        </div>
        @if(!usedInTemplate){
            <div class="form-group col-3">
                <label for="date">Datum</label>
                @if(isReadOnly){
                    <span class="form-control-plaintext">{{tender.partyValues.dateOfEvent | date: 'dd-MM-yyyy'}}</span>
                } @else {
                    <input type="date" class="form-control" id="date" placeholder="Datum" [(ngModel)]="tender.partyValues.dateOfEvent" [ngModel]="tender.partyValues.dateOfEvent | date:'yyyy-MM-dd'" (ngModelChange)="dateOfEventChanged()">
                }
            </div>
        }
        <div class="form-group col-3">
            <label for="amountofguests">Aantal gasten</label>
            @if(isReadOnly){
                <input readonly type="number" class="form-control-plaintext" id="amountofguests" [(ngModel)]="tender.partyValues.amountOfGuests">
            } @else {
                <input type="number" class="form-control" id="amountofguests" placeholder="Aantal gasten" [(ngModel)]="tender.partyValues.amountOfGuests">
            }
        </div>
    </div>
    <div class="row">
        @if(!isReadOnly){
            <div class="form-group col-3">
                <label for="eventType">Gelegenheidstype</label>
                <select id="eventType" class="form-select" [(ngModel)]="tender.partyValues.eventTypeReference">
                    @for(eventType of eventTypes; track eventType.$id){
                        <option [value]="eventType.$id">{{eventType.name}}</option>
                    }
                </select>
            </div>
        }
        <div class="form-group col-3">
            <label for="starttime">Aanvang</label>
            @if(isReadOnly){
                <input readonly type="text" class="form-control-plaintext" id="starttime" [(ngModel)]="tender.partyValues.startTime">
            } @else {
                <input type="time" class="form-control" id="starttime" placeholder="Aanvang" [(ngModel)]="tender.partyValues.startTime" (ngModelChange)="startOrEndTimeChanged()">
            }
        </div>
        <div class="form-group col-3">
            <label for="endtime">Eindtijd</label>
            @if(isReadOnly){
                <input readonly type="text" class="form-control-plaintext" id="endtime" [(ngModel)]="tender.partyValues.endTime">
            } @else {
                <input type="time" class="form-control" id="endtime" placeholder="Eindtijd" [(ngModel)]="tender.partyValues.endTime" (ngModelChange)="startOrEndTimeChanged()">
            }
        </div>
        <div class="col">
            <div class="row">
                <div class="form-group col">
                    <label for="number">Duur (in uren)</label>
                    <div class="duration-wrapper">
                        @if(isReadOnly){
                            <input readonly type="text" class="form-control-plaintext" id="duration" [(ngModel)]="tender.partyValues.duration">
                        } @else {
                            <input type="text" class="form-control" readonly id="duration" placeholder="Duur" [(ngModel)]="tender.partyValues.duration">
                        }
                        @if(durationPresent && !isReadOnly){
                            <button class="btn btn-success" disabled><i class="bi bi-check-circle"></i></button>
                        } @else if(!durationPresent && !isReadOnly) {
                            <button class="btn btn-warning" disabled><i class="bi bi-exclamation-circle"></i></button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row checklist-row">
        @if(!isReadOnly){
            <div class="form-group col">
                <div class="checklist-header">
                    <label for="partyChecklist">Gelegenheids lijsten</label>
                    <span>Kies een gelegenheids lijst uit onderstaande lijst om extra velden behorend tot dit type evenement toe te voegen.</span>
                </div>
                <div class="checklist-selector col">
                    @if(establishment.eventChecklists){
                        <select [(ngModel)]="selectedChecklist" class="form-control checklist-selection" name="partyChecklist" id="partyChecklist">
                            @for(checklist of establishment.eventChecklists; track checklist.$id){
                                <option value="{{checklist.$id}}">
                                    {{checklist.name}} 
                                </option>
                            }
                        </select>
                    } @else {
                        <br/>
                        <label class="not-present">Niet Beschikbaar</label>
                    } 
                    <div class="checklist-action" >
                        <button class="btn btn-success" (click)="addSelectedEventList()">Toevoegen</button>
                        <div class="form-group display-in-tender">
                            <label for="display-checklists-in-tender">Weergeven in offerte</label>
                            <input type="checkbox" id="display-checklists-in-tender" class="form-controll" [(ngModel)]="tender.partyValues.displayChecklistsInTender">
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    @if(showFailureFeedbackChecklists){
        <div class="row">
            <div class="col feedback-section">
                <span class="failure-feedback">De gekozen lijst is al toegevoegd aan deze offerte</span>
            </div>
        </div>
    }
    @if(showEventlists() && tender.partyValues.checklists){
        <div class="row">
            <div class="col">
                @for(checklist of tender.partyValues.checklists; track checklist.$id){
                    <div class="row checklist">
                        <label [ngClass]="!isReadOnly ? 'party-checklist-name' : 'party-checklist-name-small'">{{checklist.name}}</label>
                        @for(checklistItem of sortEventListValues(checklist.checklistItems); track checklistItem.$id){
                            @if(checklistItem.cols < 5){
                                <div class="form-group col-{{checklistItem.cols}}">
                                    <label class="checklist-label" for="{{checklistItem.name}}">{{checklistItem.name}}</label>
                                    @if(isReadOnly){
                                        <input type="{{checklistItem.type}}" readonly maxlength="255" class="form-control-plaintext checklistItem" id="{{checklistItem.name}}" [(ngModel)]="checklistItem.value">
                                    } @else {
                                        <input type="{{checklistItem.type}}" maxlength="255" class="form-control checklistItem" id="{{checklistItem.name}}" placeholder="{{checklistItem.name}}" [(ngModel)]="checklistItem.value">
                                    }
                                </div>
                            } @else {
                                <div class="form-group col-{{checklistItem.cols}}">
                                    <label class="checklist-label" for="{{checklistItem.name}}">{{checklistItem.name}}</label>
                                    @if(isReadOnly){
                                        <span class="form-control-plaintext checklistItem" id="{{checklistItem.name}}">{{checklistItem.value}}</span>
                                    } @else {
                                        <textarea maxlength="255" rows="2" class="form-control checklistItem" id="{{checklistItem.name}}" placeholder="{{checklistItem.name}}" [(ngModel)]="checklistItem.value"></textarea>
                                    }
                                </div>
                            }
                        }
                        @if(!isReadOnly){
                            <div class="checklist-actions">
                                <button class="btn btn-danger" (click)="removeEventList(checklist)"><i class="bi bi-trash"></i></button>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }
}
