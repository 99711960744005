import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { ChecklistService } from 'src/app/services/Checklist/checklist.service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { EventTypesService } from 'src/app/services/EventTypes/event-types.service';
import { Checklist } from 'src/app/shared/objects/components/checklist';
import { Establishment } from 'src/app/shared/objects/establishment';
import { EventType } from 'src/app/shared/objects/eventType';
import { Tender } from 'src/app/shared/objects/tender';
import { CommonModule } from '@angular/common';
import { ChecklistItem } from 'src/app/shared/objects/components/checklist-item';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule
  ],
  selector: 'app-party-values',
  templateUrl: './party-values.component.html',
  styleUrls: ['./party-values.component.scss']
})
export class PartyValuesComponent implements OnInit {

  @Input() tender?: Tender;
  @Input() establishment?: Establishment;
  @Input() isReadOnly?: boolean;
  @Input() usedInTemplate?: boolean;
  @Input() isSheet?: boolean; 
  @Input() isKitchenSheet?: boolean;

  selectedChecklist?: number;
  durationPresent?: boolean;
  noEndTime: string = "Geen Eindtijd";
  eventTypes?: EventType[];
  showFailureFeedbackChecklists?: boolean;

  constructor(private eventTypeService: EventTypesService, private communicationService: InternalCommunicationService, private checklistService: ChecklistService) { }

  ngOnInit(): void {
    if (this.establishment) {
      var establishmentContainer = this.communicationService.establishmentContainer.getValue();

      if(establishmentContainer?.establishment?.$id == this.establishment.$id && (establishmentContainer?.checklists?.length ?? 0) > 0){
        this.establishment.eventChecklists = establishmentContainer.checklists;
      } else {
        this.checklistService.getEstablishmentChecklists(this.establishment.$id).then(checklists => {
          if (this.establishment) {
            this.establishment.eventChecklists = checklists;
  
            if(establishmentContainer){
              establishmentContainer.checklists = checklists;
            }
          }
        });
      }

      if(establishmentContainer?.establishment?.$id == this.establishment.$id && (establishmentContainer?.eventTypes?.length ?? 0) > 0){
        this.eventTypes = establishmentContainer.eventTypes;
      } else {
        this.eventTypeService.getEstablishmentEventTypes(this.establishment.$id).then(eventTypes => {
          this.eventTypes = eventTypes.documents;
        });
      }
    }

    // Check if duration is present
    if(this.tender?.partyValues?.duration != this.noEndTime && this.tender?.partyValues?.duration != ""){
      this.durationPresent = true;
    }

    // Trigger event if value is not filled in yet
    if(!this.tender?.partyValues?.duration || this.tender?.partyValues?.duration == ""){
      this.startOrEndTimeChanged();
    }
  }

  sortEventListValues(eventListItems?: ChecklistItem[]){
    return eventListItems?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }

  /**
   * This method will determine if the checklists may be displayd or not
   * @returns 
   */
  showEventlists(){
    if(!this.isReadOnly){
      return true;
    }

    if(this.tender?.partyValues?.displayChecklistsInTender && !this.isSheet && !this.isKitchenSheet){
      return true;
    }

    return false;
  }

  /**
   * This method will calculate the length of the event
   */
  startOrEndTimeChanged(){
    if(this.tender?.partyValues?.startTime && this.tender?.partyValues?.endTime){
      var start = moment(this.tender.partyValues.startTime, "HH:mm");
      var end = moment(this.tender.partyValues.endTime, "HH:mm");
      if( end.isBefore(start) ){
        end.add(1, 'day');
      }
      var dur = moment.duration(end.diff(start))
      var result = dur.asHours();
      this.tender.partyValues.duration = result.toString().replace(".", ",");
      this.durationPresent = true;
      return;
    } else if(this.tender?.partyValues && this.tender?.partyValues?.startTime) {
      this.tender.partyValues.duration = this.noEndTime;
    } else if(this.tender?.partyValues){
      this.tender.partyValues.duration = "";
    }

    this.durationPresent = false;
  }

  /**
   * This method will remove a  from the current tender
   * An additional save is needed to make the changes permanent
   * @param eventList 
   */
  removeEventList(checklist: Checklist){
    if(!this.tender?.partyValues){
      return;
    }

    // Check if Checklist is present in the database
    if(checklist.$id && checklist.$id != ""){
      if(this.tender.partyValues && !this.tender.partyValues.checklistItemsToRemove){
        this.tender.partyValues.checklistItemsToRemove = [];
      }

      this.tender?.partyValues?.checklistItemsToRemove?.push(checklist);
    }

    // Remove from visible list
    this.tender.partyValues.checklists = this.tender.partyValues.checklists?.filter(c => c != checklist);
  }

  /**
   * This method will add the checklist items into the checklist section
   * @returns 
   */
  addSelectedEventList() {
    var checklistDropdown = document.getElementById("partyChecklist") as HTMLSelectElement;

    if (!checklistDropdown || !this.establishment || !this.tender || !this.tender?.partyValues) {
      return;
    }

    var selectedElement = checklistDropdown.value;
    
    if (!selectedElement || !this.establishment.eventChecklists) {
      return;
    }

    var checklistToAdd = this.establishment.eventChecklists.filter(c => c.$id == selectedElement);

    if(checklistToAdd.length > 0){
      // Check if checklist was not added before
      if(this.tender.partyValues.checklists?.filter(c => c.checklistTemplateId == checklistToAdd[0].$id).length ?? 0 > 0){
        this.showFailureFeedbackChecklists = true;

        setTimeout(() => {
          this.showFailureFeedbackChecklists = false;
        }, 5000);
        return;
      }

      var clonedChecklist: Checklist = JSON.parse(JSON.stringify(checklistToAdd[0]));
      // Clear checklist and checklist values to prevent from using template/previous values
      clonedChecklist.checklistTemplateId = clonedChecklist.$id.toString();
      clonedChecklist.$id = "";
      clonedChecklist.checklistItems?.forEach(checklistItem => {
        checklistItem.$id = "";
        // Reset value because otherwise the value will be coppied from other tenders
        checklistItem.value = "";
      });
  
      // Add checklist to list
      if(!this.tender.partyValues.checklists){
        this.tender.partyValues.checklists = [clonedChecklist];
      } else {
        this.tender.partyValues.checklists.push(clonedChecklist);
      }
    }
  }

  /**
   * This method will be triggerd when the date of event changes
   */
  dateOfEventChanged() {
    if (this.tender?.partyValues?.dateOfEvent) {
      var eventDate = new Date(this.tender.partyValues.dateOfEvent);
      eventDate.setDate(eventDate.getDate() + 14)
      this.communicationService.proposedExpirationDate.next(eventDate);
    }
  }
}
